// plugins/geolocation.js
import { getGeolocationRegion } from "~/services/user-locale";
import { Plugin } from '@nuxt/types'

const geolocationClient: Plugin = async ({ app, store }, inject) => {
  console.log("Geolocation plugin initialized");
  
  try {
    const region = await getGeolocationRegion(app);

    if (region) {
      console.log("Detected Region:", region);
      inject("region", region);
    } else {
      console.log("No region detected");
    }
  } catch (error) {
    console.error("Error fetching geolocation region:", error);
  }
};

export default geolocationClient
