import { render, staticRenderFns } from "./default.vue?vue&type=template&id=7be6c4d4&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=7be6c4d4&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7be6c4d4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonNavBar: require('/workspace/components/common/nav-bar/index.vue').default,CommonVideoNavbar: require('/workspace/components/common/video-navbar/index.vue').default,BSGIcon: require('/workspace/styleguide/components/Icon.vue').default,CommonFooter: require('/workspace/components/common/footer/index.vue').default,CommonRegionLanguageSelector: require('/workspace/components/common/region-language-selector/index.vue').default,CommonGeolocationSwitcher: require('/workspace/components/common/geolocation-switcher/index.vue').default})
