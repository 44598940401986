import { Context } from '@nuxt/types'
import cookie from 'js-cookie'
import {
  defaultRegion,
  isoToLanguage,
  isoToRegion,
  isoToSlug,
  makeIso,
  slugToIso,
  userSlugFromRegion,
} from './i18n'
import { pyramidConfig, PyramidConfigResponse } from '~/api/pyramid'
import appConfig from '~/app.config'
import { sleep } from "~/styleguide/utils";

export const saveUserLocale = (userLocale: string): string | undefined =>
  cookie.set(appConfig.cookies.userLocale.name, userLocale, {
    expires: appConfig.cookies.userLocale.lifetimeDays,
    secure: appConfig.cookies.secure,
  })

const getSavedUserLocale = (): string | undefined =>
  cookie.get(appConfig.cookies.userLocale.name)

export const getGeolocationRegion = async ({
                                             $axios,
                                             $vuex,
                                           }: {
  $axios: Context['$axios']
  $vuex: Context['$vuex']
}): Promise<string | undefined> => {
  try {
    const { url, config } = pyramidConfig({
      locale: $vuex.state.i18n.locale,
    })
    const response: PyramidConfigResponse = await $axios.$get(url, config)
    // Stellen Sie sicher, dass die Vuex-Action ein Promise zurückgibt
    await $vuex.dispatch('setBaseRegion', response.region.original_iso_code)

    // Warten Sie einen kurzen Moment, um sicherzustellen, dass der State aktualisiert wurde
    await new Promise(resolve => setTimeout(resolve, 0))

    return response.region.mapped_region
  } catch (e) {
    console.error("Error in getGeolocationRegion:", e)
    return undefined
  }
}

export const getUserLocale = async (ctx: Context): Promise<string> => {
  const savedUserLocale = getSavedUserLocale()
  if (savedUserLocale) return savedUserLocale
  const geolocationRegion = await getGeolocationRegion(ctx)
  const userIso = geolocationRegion
    ? userSlugFromRegion(geolocationRegion)
    : userSlugFromRegion(defaultRegion)
  return isoToSlug(userIso)
}

export const saveVideoLanguage = (videoLanguage: string): string | undefined =>
  cookie.set(appConfig.cookies.videoLanguage.name, videoLanguage, {
    expires: appConfig.cookies.videoLanguage.lifetimeDays,
    secure: appConfig.cookies.secure,
  })

const getSavedVideoLanguage = (): string | undefined =>
  cookie.get(appConfig.cookies.videoLanguage.name)

export const getVideoLocale = (userLocale: string): string => {
  const userIso = slugToIso(userLocale)
  const userRegion = isoToRegion(userIso)
  const savedVideoLanguage = getSavedVideoLanguage()
  if (
    savedVideoLanguage &&
    appConfig.regions
      .find((r) => r.id === userRegion)!
      .languages.includes(savedVideoLanguage)
  )
    return isoToSlug(makeIso(userRegion, savedVideoLanguage))

  const userLanguage = isoToLanguage(userIso)
  if (
    (appConfig.availableVideoLanguages as readonly string[]).includes(
      userLanguage
    )
  )
    return userLocale
  return isoToSlug(makeIso(userRegion, 'en'))
}
